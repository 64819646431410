/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TableWidget } from "../../../_start/partials/widgets/tables/TableWidget";
import { getActiveOrders, getTables } from "../../services/api";
import { TableOrderInfo } from "../../types/table";

export const SalesPage: React.FC = () => {
  const history = useHistory();
  const [sales, setSales] = useState<TableOrderInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    Promise.all([getActiveOrders(), getTables()]).then((results) => {
      const nonTableOrders = results[0];
      const allTableOrders = results[1];
      const tableOrders = allTableOrders.flatMap((d: any) => d.tables).filter((o: any) => o.active_order !== null);
      const orders = nonTableOrders.concat(tableOrders);

      setSales(orders);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="row g-0 g-xl-5 g-xxl-8" style={(loading || sales?.length === 0) ? {justifyContent: 'center', alignContent: 'center', height: '100%'} : {}}>
      { loading ? (
        <span
          className="spinner-border text-primary ms-3 mt-6"
          role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </span>
        ): (
          sales.length > 0 ? (
            sales.map((table, index) => (
              <div className="col-xl-4" key={index} onClick={() => history.push(`/${table.room_id ? 'table-order' : 'order'}/${table.id}`)}>
                <TableWidget className="card-stretch mb-5 mb-xxl-8" table={table}/>
              </div>
            ))
          ) : (
            <p className="fs-1 fw-light text-center text-muted">Henüz açık bir adisyon bulunmamaktadır.</p>
          )
        )
      }
      </div>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from "../../../../../_start/helpers";
import { Dictionary } from "../../../../types/dictionary";
import { OrderItemPayment } from "../../../../types/orderItemPayment";
import { createPayment } from "../../../../services/api";
import { OrderSelector } from "../../../../types/orderSelector";

type Props = {
  show: boolean;
  handleClose: () => void;
  paymentInfo: Dictionary<OrderItemPayment>,
  order: OrderSelector;
  handleReload: () => void;
};

enum PaymentMethods {
  cash = 'cash',
  credit_card = 'credit_card'
}

const paymentMethods = {
  cash: 'Nakit',
  credit_card: 'Kredi Kartı',
  multinet: 'Multinet',
  edenred: 'Edenred',
  pluxee: 'Pluxee'
};

const PaymentModal: React.FC<Props> = ({ show, handleClose, paymentInfo, handleReload, order }) => {
  const [paymentMethod, setPaymentMethod] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setPaymentMethod(undefined);
    handleClose();
  };

  const savePayment = () => {
    setLoading(true);
    const data = {
      payment_info: paymentInfo,
      payment_method: paymentMethod,
      paid_total: Object.entries(paymentInfo).map(([k, v]) => v).reduce((total, item) => total + item.count * Number(item.price), 0)
    };

    createPayment(order.orderId.toString(), data).then(() => {
      handleReload();
      setLoading(false);
      onClose();
    });
  };

  return (
    <>
      <Modal
        id="change-order-item-modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered mw-1000px"
        show={show}
        onHide={onClose}
      >
        <div className="container px-10 py-10">

          <div className="modal-header py-2 d-flex">
            <h5 className="modal-title">Ödeme Türü</h5>
            <div
              className="btn btn-icon btn-sm btn-light-primary"
              onClick={onClose}
            >
              <KTSVG
                className="svg-icon-2"
                path="/media/icons/duotone/Navigation/Close.svg"
              />
            </div>
          </div>
          <div className="overlay overlay-block">
            <div className="overlay-wrapper">
              <div className="modal-body" style={{ height: '25vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <div className="row" data-kt-control="toggle-buttons">
                  {Object.entries(paymentMethods).map(([k, v], index) => (
                    <React.Fragment key={index}>
                      <input
                        type="radio"
                        className="btn-check"
                        id={`payment-${k}`}
                        name="form-fleet"
                        value={k}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        checked={paymentMethod === k}
                      />
                      <label
                        className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-90px ${paymentMethod === k ? "active" : ""
                          }`}
                        htmlFor={`payment-${k}`}
                      >
                        <span className="text-gray-800 fw-bold">{v}</span>
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="modal-footer" style={{ justifyContent: 'space-between' }}>
                <a onClick={onClose} className="btn btn-danger">İptal</a>
                <a onClick={savePayment} className={`btn btn-primary ${!paymentMethod ? 'disabled' : ''}`}>Devam Et</a>
              </div>
            </div>
            { loading &&
              <div className="overlay-layer rounded bg-white bg-opacity-50">
                <div
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Yükleniyor...</span>
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export { PaymentModal };

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "../../types/table";
import { TableWidget } from "../../../_start/partials/widgets/tables/TableWidget";
import { usePageData } from "../../../_start/layout/core";
import { getTables } from "../../services/api";

interface Props {
  roomId?: string;
}

export const TablesPage: React.FC<Props> = ({ roomId }) => {
  const history = useHistory();
  const { setPageTitle } = usePageData();
  const [tables, setTables] = useState<Table[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getTables(roomId).then(data => {
      let tables = [];
      let roomName = '';
      tables = data.flatMap((d: any) => d.tables);

      if (!roomId) {
        roomName = 'Tüm';
      } else {
        roomName = data[0].name;
      }
      setTables(tables);
      setPageTitle(`${roomName} Masalar`);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="row g-0 g-xl-5 g-xxl-8" style={(loading || tables?.length === 0) ? {justifyContent: 'center', alignContent: 'center', height: '100%'} : {}}>
      { loading ? (
        <span
          className="spinner-border text-primary ms-3 mt-6"
          role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </span>
        ): (
          tables.length > 0 ? (
            tables.map((table, index) => (
              <div className="col-xl-4" key={index} onClick={() => history.push(`/table-order/${table.id}`)}>
                <TableWidget className="card-stretch mb-5 mb-xxl-8" table={table}/>
              </div>
            ))
          ) : (
            <p className="fs-1 fw-light text-center text-muted">Henüz hiçbir masa tanımlamadınız. Daha fazlası için yönetim panelini ziyaret edebilirsiniz.</p>
          )
        )
      }
      </div>
    </>
  );
};

import React from "react";
import { AsideMenuItem } from "./AsideMenuItem";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";

export function AsideMenuMain() {

  const auth = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  return (
    <>
      {" "}
      <>
      <span className="fw-bolder fs-4">{auth ? (auth as any).name : ''}</span>

        <>
          <div className="menu-item mt-10">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              Pano
            </h4>
          </div>
          <AsideMenuItem to="/dashboard" title="Özet" />
          { (auth as any).type !== 'attendant' && 
          <AsideMenuItem to="/closed-orders" title="Geçmiş Siparişler" />
          }
        </>
        <>
          <div className="menu-item mt-10">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              POS
            </h4>
          </div>
          <AsideMenuItem to="/rooms" title="Alanlar" />
          <AsideMenuItem to="/tables" title="Masalar" />
          <AsideMenuItem to="/orders" title="Adisyonlar " />
          { (auth as any).type !== 'attendant' &&
          <AsideMenuItem to="/order/create" title="Sipariş Oluştur" />
          }
        </>

        <div style={{position: 'absolute', bottom: 0}}>
          <div className="menu-item mt-10">
            <h4 className="menu-content text-muted mb-0 fs-6 fw-bold text-uppercase">
              Kullanıcı Hesabı
            </h4>
          </div>
          <AsideMenuItem to="/logout" title="Çıkış Yap" />
        </div>
      </>
    </>
  );
}

import axios from "axios";
import { OrderModel } from "../types/order";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api/pos`;

export const getRooms = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/rooms`, { withCredentials: true });
    
    return response.data.map((room: any) => {
      room.totalTables = room.tables.length;
      room.activeTables = room.tables.filter((table: any) => table.active_order !== null).length;

      return room;
    });
  } catch (error) {
    console.log(error);
  }
}

export const getTables = async (roomId?: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/rooms${roomId ? `/${roomId}` : ''}/tables`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const getTableActiveOrder = async (tableId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tables/${tableId}/order`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.log(error);
    return {} as OrderModel;
  }
}

export const getActiveOrder = async (orderId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/orders/${orderId}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.log(error);
    return {} as OrderModel;
  }
}

export const getActiveOrders = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/orders/active`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const getClosedOrders = async (page: number) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/orders/closed?page=${page}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const getProducts = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/products`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const getStats = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/stats`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const createOrder = async () => {
  try {
    const response = await axios.post<{ id: number }>(`${API_BASE_URL}/orders`, { }, { withCredentials: true, withXSRFToken: true });
    return response.data.id;
  } catch (error) {
    console.log(error);
  }
}

export const createActiveOrderItems = async (orderId: string, items: any[]) => {
  return await axios.post<{ result: boolean }>(`${API_BASE_URL}/orders/${orderId}/items`, { items }, { withCredentials: true, withXSRFToken: true });
}

export const updateActiveOrderItem = async (orderId: string, item: any) => {
  return await axios.put<{ result: boolean }>(`${API_BASE_URL}/orders/${orderId}/items`, { item }, { withCredentials: true, withXSRFToken: true });
}

// export const createActiveTableOrderItems = async (tableId: string, items: any[]) => {
//   return await axios.post<{ result: boolean }>(`${API_BASE_URL}/tables/${tableId}/items`, { items }, { withCredentials: true, withXSRFToken: true });
// }

// export const updateActiveTableOrderItem = async (tableId: string, item: any) => {
//   return await axios.put<{ result: boolean }>(`${API_BASE_URL}/tables/${tableId}/items`, { item }, { withCredentials: true, withXSRFToken: true });
// }

export const openTableOrder = async (tableId: string) => {
  return await axios.post<{ id: number }>(`${API_BASE_URL}/tables/${tableId}/open`, { }, { withCredentials: true, withXSRFToken: true });
}

export const closeTableOrder = async (tableId: string) => {
  return await axios.post<{ result: boolean }>(`${API_BASE_URL}/tables/${tableId}/close`, { }, { withCredentials: true, withXSRFToken: true });
}

export const createPayment = async (orderId: string, paymentInfo: any) => {
  return await axios.post<{ result: boolean }>(`${API_BASE_URL}/orders/${orderId}/payment`, paymentInfo, { withCredentials: true, withXSRFToken: true });
}

// export const createTablePayment = async (tableId: string, paymentInfo: any) => {
//   return await axios.post<{ result: boolean }>(`${API_BASE_URL}/tables/${tableId}/payment`, paymentInfo, { withCredentials: true, withXSRFToken: true });
// }
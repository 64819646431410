/* eslint-disable react-hooks/exhaustive-deps */

import { useHistory } from "react-router-dom";
import { PageTitle } from "../../../../_start/layout/core";
import { ClosedSalesPage } from "./ClosedSalesPage";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import { useEffect } from "react";

export function ClosedSalesWrapper() {
  const history = useHistory();

  const auth = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  useEffect(() => {
    if ((auth as any).type === 'attendant') {
      history.goBack();
    }
  }, []);

  return (
    <>
      <ClosedSalesPage/>
      <PageTitle>Geçmiş Siparişler</PageTitle>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  PageTitle,
} from "../../../_start/layout/core";
import { TablesPage } from "./TablesPage";
import { useParams } from "react-router-dom";

export function TablesWrapper() {
  const { roomId } = useParams<{ roomId?: string }>();

  return (
    <>
      <TablesPage roomId={roomId}/>
      <PageTitle>Masalar</PageTitle>
    </>
  );
}

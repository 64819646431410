export enum OrderKind {
  Table,
  TakeAway
}

export interface OrderSelector {
  orderId: number | string;
  tableId: number | string | undefined;
  kind: OrderKind;
  // TODO id -> orderId & add tableId here
}
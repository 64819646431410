/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Carousel } from "bootstrap";
import { Crowdedness } from "../../../../app/types/stat";

type Props = {
  className: string;
  innerPadding?: string;
  stats: Crowdedness;
};

const StatsWidgetCrowdedness: React.FC<Props> = ({ className, innerPadding = "", stats }) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const element = carouselRef.current;
    if (!element) {
      return;
    }

    const carousel = new Carousel(element);
    return function cleanUp() {
      carousel.dispose();
    };
  }, []);
  return (
    <div className={`card card-custom ${className}`}>
      <div className={`card-body ${innerPadding}`}>
        <div
          className="carousel carousel-custom slide"
          id="widgetCrowdedness_carousel"
          data-bs-ride="carousel"
          data-bs-interval="8000"
          ref={carouselRef}
        >
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <span className="fs-6 text-muted fw-bolder pe-2">Doluluk</span>
            <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
              <li
                className="ms-1 active"
                data-bs-target="#widgetCrowdedness_carousel"
                data-bs-slide-to="0"
              />
              {stats.max.value > 0 && <li
                className="ms-1"
                data-bs-target="#widgetCrowdedness_carousel"
                data-bs-slide-to="1"
              />}
              {stats.min.value > 0 && <li
                className="ms-1"
                data-bs-target="#widgetCrowdedness_carousel"
                data-bs-slide-to="2"
              />}
            </ol>
          </div>
          <div className="carousel-inner pt-8">
            <div className="carousel-item active">
              <div className="d-flex flex-column justify-content-between h-100">
                <h3 className="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">
                  Genel Doluluk
                </h3>
                <p className="text-gray-600 fw-bold pt-3 mb-0">
                  Mekanınızın, tüm masalar itibari ile genel doluluk oranı <span className="text-dark fw-bolder">%{stats.overall.toFixed(2)}</span>.
                </p>
              </div>
            </div>
            {stats.max.value > 0 && <div className="carousel-item">
              <div className="d-flex flex-column justify-content-between h-100">
                <h3 className="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">
                  En Kalabalık Alan
                </h3>
                <p className="text-gray-600 fw-bold pt-3 mb-0">
                    Şu anda, <span className="text-dark fw-bolder">%{stats.max.value.toFixed(2)}</span> doluluk oranı ile en fazla doluluğa sahip alan <span className="text-dark fw-bolder">{stats.max.room}</span>.
                </p>
              </div>
            </div>}
            {stats.min.value > 0 && <div className="carousel-item">
              <div className="d-flex flex-column justify-content-between h-100">
                <h3 className="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">
                  En Sakin Alan
                </h3>
                <p className="text-gray-600 fw-bold pt-3 mb-0">
                  Şu anda, <span className="text-dark fw-bolder">%{stats.min.value.toFixed(2)}</span> doluluk oranı ile en az doluluğa sahip alan <span className="text-dark fw-bolder">{stats.min.room}</span>.
                </p>
              </div>
            </div>}
          </div>
        </div>
      </div>
      <div className="card-footer border-0 pt-0 pb-10">
        <div className="d-flex text-muted fw-bold fs-6 pb-4">
          <span className="flex-grow-1">Genel oran</span>
          <span className="">%{stats.overall}</span>
        </div>
        <div className="progress h-6px  bg-light-danger">
          <div
            className="progress-bar bg-danger"
            role="progressbar"
            style={{ width: `${stats.overall}%` }}
            aria-valuenow={stats.overall}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
    </div>
  );
};

export { StatsWidgetCrowdedness };

import React, { useState } from "react";
import { KTSVG } from "../../../helpers";
import { useTheme } from "../../core";

export function Topbar() {
  const { config } = useTheme();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showInboxComposeModal, setShowInboxComposeModal] = useState(false);

  return (
    <>
      {/* begin::Search */}
      {/* <button
        className="btn btn-icon btn-sm btn-active-bg-accent ms-1 ms-lg-6"
        onClick={() => setShowSearchModal(true)}
      >
        <KTSVG
          path="/media/icons/duotone/General/Search.svg"
          className="svg-icon-1 svg-icon-dark"
        />
      </button>
      <SearchModal
        show={showSearchModal}
        handleClose={() => setShowSearchModal(false)}
      /> */}
      {/* end::Search */}

      {/* begin::Notifications */}
      {/* <div className="ms-1 ms-lg-6">
        <button
          className="btn btn-icon btn-sm btn-active-bg-accent fw-bolder"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >
          <KTSVG
            path="/media/icons/duotone/General/Notifications1.svg"
            className="svg-icon-1 svg-icon-dark"
          />
        </button>
        <HeaderNotificationsMenu />
      </div> */}
      {/* end::Notifications */}

      {/* begin::Aside Toggler */}
      {config.aside.display && (
        <button
          className="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6"
          id="kt_aside_toggler"
        >
          <KTSVG
            path="/media/icons/duotone/Text/Menu.svg"
            className="svg-icon-1 svg-icon-dark"
          />
        </button>
      )}
      {/* end::Aside Toggler */}

      {/* begin::Sidebar Toggler */}
      {config.sidebar.display && (
        <button
          className="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6"
          id="kt_sidebar_toggler"
        >
          <KTSVG
            path="/media/icons/duotone/Text/Menu.svg"
            className="svg-icon-1 svg-icon-dark"
          />
        </button>
      )}
      {/* end::Sidebar Toggler */}
    </>
  );
}

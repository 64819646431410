import * as auth from "../../app/modules/auth";


export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken }
      } = store.getState();
      
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use((response: any) => response, (error: any) => {
    if (error.response.status === 401) {
      // Login error is 401 too but it should not be reloaded
      if (error.response.data.message !== 'Login: Bad credentials'){
        store.dispatch(auth.actions.logout());
        window.location.href = 'logout';
      }
    }
  });
}

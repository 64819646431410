import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from "../../../../../_start/helpers";
import InputNumber from "../../../../../_start/partials/widgets/shared/InputNumber";
import { createActiveOrderItems, createOrder } from "../../../../services/api";
import { OrderKind, OrderSelector } from "../../../../types/orderSelector";
import { useHistory } from "react-router-dom";

type Props = {
  show: boolean;
  handleClose: () => void;
  variants: any[];
  order: OrderSelector;
  productName: string;
  handleReload: () => void;
};

const SelectVariantModal: React.FC<Props> = ({ show, handleClose, variants, productName, handleReload, order }) => {
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState<number>(0);
  const history = useHistory();

  const loadProducts = () => {
    setLoading(true);
    setItems([]);

    const defaultCount = variants.length === 1 ? 1 : 0;
    setCount(defaultCount);
    
    const items = [];
    for (const variant of variants) {
      items.push({
        product_id: variant.product_id,
        variant_id: variant.id,
        name: `${productName}(${variant.value.title})`,
        price: variant.price,
        count: defaultCount
      });
    }

    setItems(items);
    setLoading(false);
  }

  const saveItems = async () => {
    setLoading(true);
    
    let orderId = order.orderId.toString();
    let reload = true;
    if (order.kind === OrderKind.TakeAway && order.orderId.toString() === 'create') {
      const newOrderId = await createOrder();
      if (newOrderId) {
        orderId = newOrderId.toString();
        history.replace(`/order/${newOrderId}`);
        reload = false;
      }
    }

    await createActiveOrderItems(orderId, items);
    if (reload) {
      handleReload();
    }
    setLoading(false);
    handleClose();
  }

  const onClose = () => {
    setItems([]);
    handleClose();
  };

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      dialogClassName="modal-dialog-centered mw-1000px"
      style={{height: '100vh'}}
      show={show}
      onHide={onClose}
      onEntered={loadProducts}
    >
      <div className="container px-10 py-10" style={{height: '95vh'}}>
        <div className="modal-header py-2 d-flex">
          <h5 className="modal-title">Ürün Seçin ({productName})</h5>
          <div
            className="btn btn-icon btn-sm btn-light-primary"
            onClick={onClose}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotone/Navigation/Close.svg"
            />
          </div>
        </div>
        <div className="overlay overlay-block">
          <div className="overlay-wrapper">
            <div className="modal-body" style={{height: '75vh', overflowY: 'auto'}}>
                  { items &&
                    items.map((item, index) => (
                      <div key={`${index}_${item.product_id}`}>
                        <div className="card card-custom bg-light mt-5">
                          <div className="card-header">
                              <h3 className="card-title">{item.name}</h3>
                          </div>
                          <div className="card-body">
                            <label className="form-label mt-3">Adet</label>
                            <InputNumber steps={1} defaultValue={count} onChange={(value) => { item.count = value }} minValue={1}/>
                            <label className="form-label mt-5">Fiyat</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={`${item.price} ₺`}
                              readOnly
                              style={{border: 'none', background: 'transparent'}}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  }
            </div>
            <div className="modal-footer" style={{justifyContent: 'space-between'}}>
              <a onClick={onClose} className="btn btn-danger">İptal</a>
              <a onClick={saveItems} className="btn btn-primary">Devam Et</a>
            </div>
          </div>
          { loading &&
            <div className="overlay-layer rounded bg-white bg-opacity-50">
              <div
                className="spinner-border text-primary"
                role="status">
                <span className="visually-hidden">Yükleniyor...</span>
              </div>
            </div>
          }
        </div>
      </div>
    </Modal>
  );
};

export { SelectVariantModal };

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { AddOrderItemsModal } from "./_modals/add-order-items/AddOrderItemsModal";
import { usePageData } from "../../../_start/layout/core";
import { ChangeOrderItemModal } from "./_modals/change-order-item/ChangeOrderItemModal";
import InputNumber from "../../../_start/partials/widgets/shared/InputNumber";
import { PaymentModal } from "./_modals/payment/PaymentModal";
import { Dictionary } from "../../types/dictionary";
import { OrderItemPayment, OrderItemPaymentInfo } from "../../types/orderItemPayment";
import { openTableOrder, closeTableOrder, getActiveOrder } from "../../services/api";
import { useHistory } from "react-router-dom";
import { Confirm } from "../../../_start/partials/Confirm";
import { OrderKind, OrderSelector } from "../../types/orderSelector";
import { OrderInfo, OrderItemModel, OrderModel, OrderStatus } from "../../types/order";

interface Props {
  orderLike: OrderSelector;
  orderInfo?: OrderInfo;
}

export const OrderPage: React.FC<Props> = ({ orderLike, orderInfo }) => {
  const [order, setOrder] = useState<OrderModel>();
  const [total, setTotal] = useState<number>(0);
  const [selectedOrderItemGroup, setSelectedOrderItemGroup] = useState<any[]>([]);
  const { setPageTitle } = usePageData(); 
  const [orderItemsPayments, setOrderItemsPayments] = useState<Dictionary<OrderItemPaymentInfo>>({});
  const [selectedPayments, setSelectedPayments] = useState<Dictionary<OrderItemPayment>>({});
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [showChangeOrderModal, setShowChangeOrderModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentIndex, setPaymentIndex] = useState(0);
  const [showCloseTableConfirm, setShowCloseTableConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [statusChange, setStatusChange] = useState(0);
  const history = useHistory();
  let orderLastStatus: OrderStatus | undefined = undefined;


  const loadData = async () => {
    setLoading(true);
    setOrderItemsPayments({});

    const activeOrder = await getActiveOrder(orderLike.orderId.toString());

    if (orderLike.kind === OrderKind.TakeAway) {
      // TODO if activeOrder == {}, break!
      if (orderLastStatus === OrderStatus.Active && (activeOrder as OrderModel).status === OrderStatus.Closed) {
        setStatusChange(statusChange + 1);
        history.push('/order/create');
        return;
      }
    }

    handleOrderResponse(activeOrder);
  }

  const handleOrderResponse = (order: any) => {
    const remainingPayments: Dictionary<OrderItemPaymentInfo> = {};
    let sum = 0;
    for (const items of Object.values(order.items as any[])) {
      for (const item of items) {
        sum += parseFloat(item.price);
      }

      const [paidCount, remainingCount] = calculatePaymentStatus(items);
      if (remainingCount > 0) {
        remainingPayments[items[0].pid] = {pid: items[0].pid, count: 0, unpaid_count: remainingCount, price: items[0].price};
      }
    }

    let pageTitle = order.table?.name ?? null;

    if (!pageTitle) {
      pageTitle = order.id === 0 ? 'Yeni Sipariş' : `Sipariş #${order.id}`;
    }

    setOrderItemsPayments(remainingPayments);
    setTotal(sum);
    setOrder(order);
    setPageTitle(pageTitle);
    setPaymentIndex(paymentIndex + 1);
    setLoading(false);
  }

  const openTable = () => {
    if (orderLike.kind === OrderKind.Table && orderLike.tableId) {
      openTableOrder(orderLike.tableId.toString()).then((response) => {
        orderLike.orderId = response.data.id;
        loadData();
      });
    }
  }

  const closeTable = () => {
    if (orderLike.kind === OrderKind.Table && orderLike.tableId) {
      closeTableOrder(orderLike.tableId.toString()).then(() => {
        history.goBack();
      });
    }
  }

  const handleOrderItemClick = (items: any) => {
    if (order?.status == OrderStatus.Closed) {
      return;
    }
    setSelectedOrderItemGroup(items);
    setShowChangeOrderModal(true);
  }

  const renderPaymentStatus = (items: any) => {
    const [paidCount, remainingCount] = calculatePaymentStatus(items);
    const price = items ? items[0].price : 0;
    return (
      <>
        <td className="text-end pt-10">{items ? (price * paidCount).toFixed(2) : 0} &#8378; <p>{paidCount} adet</p></td>
        <td className="pe-0 fs-6 fw-bolder text-end pt-10">{items ? (price * remainingCount).toFixed(2) : 0} &#8378; <p>{remainingCount} adet</p></td>
      </>
    )
  }

  const calculatePaymentStatus = (items: any) => {
    const paidCount = items.filter((item: OrderItemModel) => item.payment_id !== null).length;
    const remainingCount = items.length - paidCount;

    return [paidCount, remainingCount];
  }

  const updateToBePaidCount = (key: any, count: any) => {
    setOrderItemsPayments((prevItems) => ({
      ...prevItems,
      [key]: {
        ...prevItems[key],
        count: parseInt(count) || 0, // Ensure the count is a number
      },
    }));
  }

  const openPaymentModal = () => {
    const selectedItems = Object.entries(orderItemsPayments).filter(([k, v]) => v.count > 0);
    const data = Object.assign({}, ...selectedItems.map(([k, v]) => ({[v.pid.toString()]: v})));
    setSelectedPayments(data);
    setShowPaymentModal(true);
  }

  const payAll = () => {
    const items = Object.fromEntries(Object.entries(orderItemsPayments).map(([key, item]) => {
      item.count = item.unpaid_count;
      return [key, item];
    }));
    setOrderItemsPayments(items);
    setPaymentIndex(paymentIndex + 1);

    setTimeout(openPaymentModal, 300);
  }

  const handlePostPayment = () => {
    orderLastStatus = order?.status;
    loadData();
  }

  useEffect(() => {
    if (orderLike.kind === OrderKind.TakeAway && orderLike.orderId.toString() === 'create') {
      // open order
      const emptyOrder: OrderModel = {
        id: 0,
        items: {},
        amount: {},
        created_at: '',
        opening_user_name: '',
        status: OrderStatus.Active,
        closing_user_name: undefined,
        updated_at: undefined
      };
      handleOrderResponse(emptyOrder);
    } else {
      if (orderInfo) {
        orderLastStatus = order?.status;
        handleOrderResponse(orderInfo);
      }
    }
  }, [orderLike]);

  return (
    <>
      { !order || order?.created_at === null ? (
        order && 
        <React.Fragment>
          <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10vh"}}>
            <i className="fas fa-power-off fa-10x text-muted" style={{opacity: 0.1}}></i>
            <h1 className="mt-20">Masa kapalı</h1>
            <p style={{textAlign: "center"}}>Masayı direkt olarak açabilirsiniz ya da müşterizin hesabında oluşturulan QR kodu okutarak masayı aktive edebilirsiniz. Böylece müşteriniz sipariş verebilir, siparişi ödeyebilir ya da siparişe dair puanlama yapabilir.</p>
            <a onClick={openTable}
              className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6">
                Masayı Aç
            </a>
            <a onClick={() => {}}
              className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6 btn-disabled">
                Masayı QR ile Aç
            </a>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6" key={`$navlink_${statusChange}`}>
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
              >
                Siparişler
              </a>
            </li>
            {Object.values(order?.items ?? {}).length > 0 && order.status === OrderStatus.Active && <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_2"
              >
                Ödeme 
              </a>
            </li>}
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              id="kt_tab_pane_1"
              role="tabpanel"
              key={`$tab_1_${statusChange}`}
            >
              <div className="row" style={{paddingBottom: "10rem"}}>
                <div className="col-md-9 py-md-10 pe-md-10">
                { Object.values(order?.items ?? {}).length === 0 ? (
                  <p className="mt-20 text-muted" style={{
                    textAlign: "center", fontSize: "x-large"}}>Henüz ürün eklenmedi</p>
                  ) : (
                  <div className="table-responsive">
                    <table className="table align-middle table-borderless">
                      <thead>
                        <tr className="border-bottom-1 border-bottom-gray-100 fw-bolder text-muted fs-6 text-uppercase">
                          <th className="pt-5 pb-10 ps-0 ">Ürün</th>
                          <th className="pt-5 pb-10 text-end">Adet</th>
                          <th className="pt-5 pb-10 text-end">Fiyat</th>
                          <th className="pt-5 pb-10 text-end">Ara Toplam</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(order?.items ?? {}).map(([key, item], index) => (
                        <tr key={index} onClick={ () => handleOrderItemClick(item) }>
                          <td className="fw-bolder ps-0 pt-10">
                            <div className="d-flex align-items-center"><i className="fa fa-genderless text-primary fs-1 me-2"></i>{item[0].name}</div>
                          </td>
                          <td className="text-end pt-10">{item?.length}</td>
                          <td className="text-end pt-10" style={{whiteSpace: "nowrap"}}>{item ? item[0].price : 0} &#8378;</td>
                          <td className="pe-0 fs-6 fw-bolder text-end pt-10">{item ? (item[0].price * item.length).toFixed(2) : 0} &#8378;</td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  )
                }
                {Object.values(order?.items ?? {}).length > 0 && <div className="border-bottom w-100 mt-7 mb-13"></div>}
                </div>
                <div className="col-md-3 border-start-md ps-md-10 pt-md-10 text-end d-none d-sm-block">
                  <div>
                    <div>
                      { order.status === OrderStatus.Active && 
                        <a onClick={() => setShowCreateAppModal(true)}
                          className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6">
                            Sipariş Ekle
                        </a>
                      }
                    </div>
                    <div className="mt-10">
                      <div className="fs-3 fw-bolder text-muted mb-3">Toplam</div>
                      <div className="fs-2x fw-bolder">{total.toFixed(2)}&#8378;</div>
                      <div className="text-muted fw-bold mb-16">KDV dahil</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex d-sm-none bg-light" style={{
                position: 'fixed',
                bottom: 0,
                left: 0, justifyContent: 'space-between', 
                paddingLeft: '-20px', 
                paddingRight: '-20px', 
                padding: '20px', width: "100%"}}>
                <div>
                  { order.status === OrderStatus.Active && 
                    <a onClick={() => setShowCreateAppModal(true)}
                      className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6">
                        Sipariş Ekle
                    </a>
                  }
                </div>
                <div className="text-end">
                  <div className="fs-3 fw-bolder text-muted mb-3">Toplam</div>
                    <div className="fs-2x fw-bolder">{total.toFixed(2)}&#8378;</div>
                    <div className="text-muted fw-bold">KDV dahil</div>
                </div>
              </div>
            </div>
            { order.status === OrderStatus.Active && 
            <div key={`$tab_2_${statusChange}`} className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              { !loading &&
              <>
                <div className="row" style={{paddingBottom: "10rem"}}>
                  <div className="col-md-9 py-md-10 pe-md-10">
                    <div className="table-responsive" style={{paddingBottom: "10rem"}}>
                      <table className="table align-middle table-borderless">
                        <thead>
                          <tr className="border-bottom-1 border-bottom-gray-100 fw-bolder text-muted fs-6 text-uppercase">
                            <th className="pt-5 pb-10 ps-0 ">Ürün</th>
                            <th className="pt-5 pb-10 text-end">Ödenen</th>
                            <th className="pt-5 pb-10 text-end">Kalan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(order?.items ?? {}).map(([key, item], index) => (
                          <tr key={`${index}_${paymentIndex}`}>
                            <td className="fw-bolder ps-0 pt-10">
                              <div className="d-flex align-items-center"><i className={`fa fa-genderless ${orderItemsPayments[item[0].pid] ? "text-danger" : "text-primary"} fs-1 me-2`}></i>{item[0].name}</div>
                              {orderItemsPayments[item[0].pid] ? (<InputNumber steps={1} defaultValue={orderItemsPayments[item[0].pid].count} 
                                onChange={(value) => updateToBePaidCount(item[0].pid, value)} 
                                minValue={0} maxValue={calculatePaymentStatus(item)[1]}/>)
                                : (<InputNumber steps={1} defaultValue={0} 
                                  onChange={(value) => {}} 
                                  minValue={0} maxValue={0} disabled/>)}
                            </td>
                            {renderPaymentStatus(item)}
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex d-sm-none bg-light" style={{
                      position: 'fixed',
                      bottom: 0,
                      left: 0, 
                      justifyContent: 'space-between', 
                      paddingLeft: '-20px', 
                      paddingRight: '-20px', 
                      padding: '20px', width: "100%"}}>
                      { Object.values(orderItemsPayments).filter(t => t.count > 0).length > 0 ? (
                      <>
                        <div>
                          <a onClick={openPaymentModal}
                            className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6">
                              Ödeme yap
                          </a>
                        </div>
                        <div className="text-end">
                          <div className="fs-3 fw-bolder text-muted mb-3">Ara Toplam</div>
                          <div className="fs-2x fw-bolder">{Object.values(orderItemsPayments).reduce((acc, t) => acc + t.count * t.price, 0).toFixed(2)}&#8378;</div>
                          <div className="text-muted fw-bold">KDV dahil</div>
                        </div>
                      </>) : (
                      <>
                        <div>
                        {Object.values(orderItemsPayments).length > 0 ? (
                            <a onClick={payAll}
                              className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6">
                                Tümünü Öde
                            </a>
                          ) : (
                            <a onClick={() => setShowCloseTableConfirm(true)}
                              className="btn btn-danger btn-sm fw-bolder fs-6 ps-4 mt-6">
                                Masayı Kapat
                            </a>
                        )}
                        </div>
                        <div className="text-center">
                          <div className="fs-3 fw-bolder text-muted mb-3">Kalan</div>
                          <div className="fs-2x fw-bolder">{(parseFloat(order.amount.total) - parseFloat(order.amount.payment)).toFixed(2)}&#8378;</div>
                          <div className="text-muted fw-bold">Ödenen: {parseFloat(order.amount.payment).toFixed(2)}&#8378;</div>
                        </div>
                        <div className="text-end">
                          <div className="fs-3 fw-bolder text-muted mb-3">Toplam</div>
                          <div className="fs-2x fw-bolder">{parseFloat(order.amount.total).toFixed(2)}&#8378;</div>
                          <div className="text-muted fw-bold">KDV dahil</div>
                        </div>
                      </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 border-start-md ps-md-10 pt-md-10 text-end d-none d-sm-block">
                    <div style={{position: 'sticky', top: '10vh'}}>
                      { Object.values(orderItemsPayments).filter(t => t.count > 0).length > 0 ? (
                      <>
                        <div>
                          <a onClick={openPaymentModal}
                            className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6">
                              Ödeme yap
                          </a>
                        </div>
                        <div className="text-end">
                          <div className="fs-3 fw-bolder text-muted mb-3 mt-10">Ara Toplam</div>
                          <div className="fs-2x fw-bolder">{Object.values(orderItemsPayments).reduce((acc, t) => acc + t.count * t.price, 0).toFixed(2)}&#8378;</div>
                          <div className="text-muted fw-bold">KDV dahil</div>
                        </div>
                      </>) : (
                      <>
                        <div>
                        {Object.values(orderItemsPayments).length > 0 ? (
                            <a onClick={payAll}
                              className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6">
                                Tümünü Öde
                            </a>
                          ) : (
                            <a onClick={() => setShowCloseTableConfirm(true)}
                              className="btn btn-danger btn-sm fw-bolder fs-6 ps-4 mt-6">
                                Masayı Kapat
                            </a>
                        )}
                        </div>
                        <div className="text-end">
                          <div className="fs-3 fw-bolder text-muted mb-3 mt-10">Kalan</div>
                          <div className="fs-2x fw-bolder">{(parseFloat(order.amount.total) - parseFloat(order.amount.payment)).toFixed(2)}&#8378;</div>

                          <div className="fs-3 fw-bolder text-muted mb-3 mt-10">Ödenen</div>
                          <div className="fs-2x fw-bolder">{parseFloat(order.amount.payment).toFixed(2)}&#8378;</div>

                          <div className="fs-3 fw-bolder text-muted mb-3 mt-10">Toplam</div>
                          <div className="fs-2x fw-bolder">{parseFloat(order.amount.total).toFixed(2)}&#8378;</div>
                          <div className="text-muted fw-bold">KDV dahil</div>
                        </div>
                      </>
                      )}
                    </div>
                  </div>
                </div>
              </>
              }
              { loading &&
                <div className="overlay-layer rounded" style={{height: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center'}}>
                  <div
                    className="spinner-border text-primary"
                    role="status">
                  </div>
                </div>
              }
            </div>
            }
          </div>

          <AddOrderItemsModal
            show={showCreateAppModal}
            handleClose={() => setShowCreateAppModal(false)}
            order={orderLike}
            handleReload={() => { loadData(); }}
          />
          
          <ChangeOrderItemModal
            show={showChangeOrderModal}
            handleClose={() => setShowChangeOrderModal(false)}
            order={orderLike}
            handleReload={() => { loadData(); }}
            orderItemGroup={selectedOrderItemGroup}
          />

          <PaymentModal
            show={showPaymentModal}
            handleClose={() => setShowPaymentModal(false)}
            order={orderLike}
            handleReload={handlePostPayment}
            paymentInfo={selectedPayments}
          />

          <Confirm show={showCloseTableConfirm} handleClose={() => setShowCloseTableConfirm(false)} handleConfirm={closeTable} title="Masayı Kapat" message="Onaylamanız durumunda masa kapatılacaktır. Yeni sipariş için masayı tekrar açabilirsiniz."></Confirm>
        </React.Fragment>
      )}
    </>
  );
};

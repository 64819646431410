/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { StatsWidgetCrowdedness } from "../../../_start/partials/widgets/stats/StatsWidgetCrowdedness";
import { GreetingWidget } from "../../../_start/partials/widgets/stats/GreetingWidget";
import { getStats } from "../../services/api";
import { StatModel } from "../../types/stat";
import { StatsWidgetTableCosts } from "../../../_start/partials/widgets/stats/StatsWidgetTableCosts";
import { StatsWidgetSales } from "../../../_start/partials/widgets/stats/StatsWidgetSales";
import { ElementAnimateUtil } from "../../../_start/assets/ts/_utils";

export const DashboardPage: React.FC = () => {

  const [stats, setStats] = useState<StatModel>({
    crowdedness: {overall: 0, min: {room: '', value: 0}, max: {room: '', value: 0}},
    total_costs: {min: {table: '', value: 0}, max: {table: '', value: 0}},
    costs_per_minute: {min: {table: '', value: 0}, max: {table: '', value: 0}},
    total_sales: {day: {current: 0, last: 0}, week: {current: 0, last: 0}, month: {current: 0, last: 0},}
  } as StatModel);

  useEffect(() => {
    ElementAnimateUtil.scrollTop(0, 100);
    getStats().then((res) => {
      setStats(res);
    });
  }, []);

  return (
    <>
      <div className="row g-0 g-xl-5 g-xxl-8">

        <div className="col-xl-12">
          <GreetingWidget className="card-stretch mb-5 mb-xxl-8" />
        </div>

        <div className="col-xl-4">
          <StatsWidgetCrowdedness className="card-stretch mb-5 mb-xxl-8" stats={stats.crowdedness} />
        </div>

        <div className="col-xl-4">
          <StatsWidgetTableCosts className="card-stretch mb-5 mb-xxl-8" stats={stats.total_costs} />
        </div>
      </div>

      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xxl-4">
          <StatsWidgetSales className="card-stretch mb-5 mb-xxl-8" titles={['Günlük Satış', 'Dünkü Satış']} values={stats.total_sales.day} />
        </div>

        <div className="col-xxl-4">
          <StatsWidgetSales className="card-stretch mb-5 mb-xxl-8" titles={['Haftalık Satış', 'Geçen Haftaki Satış']} values={stats.total_sales.week} />
        </div>

        <div className="col-xxl-4">
          <StatsWidgetSales className="card-stretch mb-5 mb-xxl-8" titles={['Aylık Satış', 'Geçen Ayın Satışı']} values={stats.total_sales.month} />
        </div>
      </div>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Carousel } from "bootstrap";
import { TableCosts } from "../../../../app/types/stat";

type Props = {
  className: string;
  innerPadding?: string;
  stats: TableCosts;
};

const StatsWidgetTableCosts: React.FC<Props> = ({ className, innerPadding = "", stats }) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const element = carouselRef.current;
    if (!element) {
      return;
    }

    const carousel = new Carousel(element);
    return function cleanUp() {
      carousel.dispose();
    };
  }, []);
  return (
    <div className={`card card-custom ${className}`}>
      <div className={`card-body ${innerPadding}`}>
        <div
          className="carousel carousel-custom slide"
          id="widgetTableCosts_carousel"
          data-bs-ride="carousel"
          data-bs-interval="8000"
          ref={carouselRef}
        >
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <span className="fs-6 text-muted fw-bolder pe-2">Masa Harcamaları</span>
            <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
              {stats.max.value > 0 && <li
                className="ms-1 active"
                data-bs-target="#widgetTableCosts_carousel"
                data-bs-slide-to="0"
              />}
              {stats.min.value > 0 && <li
                className="ms-1"
                data-bs-target="#widgetTableCosts_carousel"
                data-bs-slide-to="1"
              />}
            </ol>
          </div>
          <div className="carousel-inner pt-8">
            {stats.max.value > 0 && <div className="carousel-item active">
              <div className="d-flex flex-column justify-content-between h-100">
                <h3 className="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">
                  En Çok Harcama Yapan Masa
                </h3>
                <p className="text-gray-600 fw-bold pt-3 mb-0">
                    Şu anda, <span className="text-dark fw-bolder">{stats.max.value.toFixed(2)} &#8378;</span> ile en fazla harcama yapan masa <span className="text-dark fw-bolder">{stats.max.table}</span>.
                </p>
              </div>
            </div>}
            {stats.min.value > 0 && <div className="carousel-item">
              <div className="d-flex flex-column justify-content-between h-100">
                <h3 className="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">
                  En Az Harcama Yapan Masa
                </h3>
                <p className="text-gray-600 fw-bold pt-3 mb-0">
                  Şu anda, <span className="text-dark fw-bolder">{stats.min.value.toFixed(2)} &#8378;</span> ile en az harcama yapan masa <span className="text-dark fw-bolder">{stats.min.table}</span>.
                </p>
              </div>
            </div>}
            {stats.max.value == 0 && stats.min.value == 0 && <div className="carousel-item active">
              <div className="d-flex flex-column justify-content-between h-100">
                <h3 className="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">
                  &nbsp;
                </h3>
                <p className="text-gray-600 fw-bold pt-3 mb-0">
                  Henüz <span className="text-dark fw-bolder">veri yok</span>.
                </p>
              </div>
            </div>}
          </div>
        </div>
      </div>
      <div className="card-footer border-0 pt-0 pb-10"></div>
    </div>
  );
};

export { StatsWidgetTableCosts };

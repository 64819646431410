import React, { ChangeEvent, useEffect } from "react";

// import "../../../assets/sass/components/_input-number.scss";
import { useInterval } from "../../../helpers/IntervalHook";

type InputNumberProps = { minValue?: number, maxValue?: number, defaultValue?: number, steps: number, onChange?: (value: number) => void, disabled?: boolean };

export default function InputNumber({ steps, onChange, defaultValue, minValue, maxValue, disabled }: InputNumberProps = { steps: 1 }) {
    const [value, setValue] = React.useState<number>(defaultValue ?? 0);
    const [mouseDownDirection, setMouseDownDirection] = React.useState<'up' | 'down' | undefined>(undefined);
    const max = (num: number) => (num < 0 ? 4 : 3);

    // useInterval(() => handleButtonChange(mouseDownDirection), mouseDownDirection ? 100 : null);

    const handleChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
        setValue(curr => {
            if (!Boolean(value)) { return 0; }
            const numeric = parseInt(value, 10);
            const maxLength = max(numeric);

            if (value.length > maxLength) {
                return curr;
            }

            return (value.length <= maxLength ? numeric : curr);
        });
    };

    const handleButtonChange = (direction?: 'up' | 'down') => {
        setValue(curr => {
            let next: number;

            switch (direction) {
                case "up":
                    next = curr + (steps || 1);
                    break;
                case "down":
                    next = curr - (steps || 1);
                    break;
                default:
                    next = curr;
                    break;
            }

            minValue ??= -Infinity;
            if (next < minValue) {
                return curr;
            }

            maxValue ??= Infinity;
            if (next > maxValue) {
                return curr;
            }

            return `${next}`.length <= max(curr) ? next : curr;
        });
    };

    useEffect(() => {
        onChange && onChange(value);
    }, [value]);

    return (
        <div className="input-number" style={{display: 'flex', ...(disabled ? {opacity: 0.2, pointerEvents: "none"} : {})}}>
            <button className="btn btn-secondary"
                onClick={() => handleButtonChange("down")}
                onMouseDown={() => setMouseDownDirection("down")}
                onMouseOut={() => setMouseDownDirection(undefined)}
                onMouseUp={() => setMouseDownDirection(undefined)}
            >-</button>
            <input className="form-control" type="number" step={steps} value={value} onChange={handleChange} disabled style={{width: '20vw', background: 'transparent', textAlign: 'center', border: 'none'}}/>
            <button className="btn btn-secondary"
                onClick={() => handleButtonChange("up")}
                onMouseDown={() => setMouseDownDirection("up")}
                onMouseUp={() => setMouseDownDirection(undefined)}
                onMouseOut={() => setMouseDownDirection(undefined)}
            >+</button>
        </div>
    );
}

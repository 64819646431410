/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { RoomWidget } from "../../../_start/partials/widgets/rooms/RoomWidget";
import { useHistory } from "react-router-dom";
import { Room } from "../../types/room";
import { getRooms } from "../../services/api";

export const RoomsPage: React.FC = () => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getRooms().then(rooms => {
      setRooms(rooms);
      setLoading(false);
    });
  }, []);
  
  return (
    <>
      <div className="row g-0 g-xl-5 g-xxl-8" style={(loading || rooms?.length === 0) ? {justifyContent: 'center', alignContent: 'center', height: '100%'} : {}}>
      { loading ? (
        <span
          className="spinner-border text-primary ms-3 mt-6"
          role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </span>
        ): (
          rooms.length > 0 ? (
            rooms.map((room, index) => (
              <div className="col-xl-4" key={index} onClick={() => history.push(`/tables/${room.id}`)}>
                <RoomWidget className="card-stretch mb-5 mb-xxl-8" room={room}/>
              </div>
            ))
          ) : (
            <p className="fs-1 fw-light text-center text-muted">Henüz hiçbir alan tanımlamadınız. Daha fazlası için yönetim panelini ziyaret edebilirsiniz.</p>
          )
        )
      }
      </div>
    </>
  );
};

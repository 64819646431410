import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from "../../../../../_start/helpers";
import { SelectVariantModal } from "./SelectVariantModal";
import { getProducts } from "../../../../services/api";
import { OrderSelector } from "../../../../types/orderSelector";

type Props = {
  show: boolean;
  handleClose: () => void;
  order: OrderSelector;
  handleReload: () => void;
};

const AddOrderItemsModal: React.FC<Props> = ({ show, handleClose, handleReload, order }) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [product, setProduct] = useState<any>({name: ''});
  const [showSelectVariantModal, setShowSelectVariantModal] = useState(false);
  const [variants, setVariants] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const loadProducts = () => {
    setLoading(true);
    getProducts().then(products => {
      setCategories(products);
      setLoading(false);
    });
  }

  const displayProducts = (products: any[]) => {
    setProducts(products);
  }

  const addItem = (product: any, customPrice?: any) => {
    const price = customPrice ?? product.price;

    setVariants(product.variants);
    setProduct(product);
    setShowSelectVariantModal(true);
  }

  const onClose = () => {
    setLoading(true);
    setCategories([]);
    setProducts([]);
    handleClose();
  }

  return (
    <Modal
      id="add-order-item-modal"
      tabIndex={-1}
      dialogClassName="modal-dialog-centered mw-1000px"
      style={{height: '100vh'}}
      show={show}
      onHide={onClose}
      onEntered={loadProducts}
    >
      <div className="container px-10 py-10" style={{height: '95vh'}}>
        <div className="modal-header py-2 d-flex">
          <h5 className="modal-title">Ürün Seçimi</h5>
          <div
            className="btn btn-icon btn-sm btn-light-primary"
            onClick={onClose}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotone/Navigation/Close.svg"
            />
          </div>
        </div>
        <div className="overlay overlay-block">
          <div className="overlay-wrapper">
            <div className="modal-body">
              { !loading && categories.length === 0 && 
                <p className="fs-2 fw-light text-center text-muted">Henüz hiçbir ürün oluşturmadınız. Daha fazlası için yönetim panelini ziyaret edebilirsiniz.</p>
              }
              {
                categories.map((category, index) => (
                  <a onClick={() => displayProducts(category.products)} className="btn btn-primary btn-category btn-sm fw-bolder fs-6 ps-4" key={index} style={{marginLeft: '10px'}}>
                      {category.title}
                  </a>
                ))
              }
              {categories.length > 0 && <div className="separator border-1 my-5"></div>}
              <div>
              {
                products.map((product, index) => (
                  <a onClick={() => addItem(product)} className="btn btn-secondary btn-sm fw-bolder fs-6 ps-4 mt-6" key={index} style={{marginLeft: '10px'}}>
                      {product.name}
                  </a>
                ))
              }
              </div>
            </div>
          </div>
          { loading &&
            <div className="overlay-layer rounded bg-white bg-opacity-50">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">Yükleniyor...</span>
              </div>
            </div>
          }
        </div>
      </div>

      <SelectVariantModal
        show={showSelectVariantModal}
        handleClose={() => setShowSelectVariantModal(false)}
        variants={variants}
        order={order}
        productName={product.name}
        handleReload={handleReload}
      />
    </Modal>
  );
};

export { AddOrderItemsModal };

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from "../helpers";

export enum ConfirmResult {
  Ok,
  Cancel,
  Close
}

type Props = {
  show: boolean;
  handleConfirm: (result: ConfirmResult) => void;
  handleClose: () => void;
  title: string;
  message: string;
  okBtn?: string;
  cancelBtn?: string;
};

const Confirm: React.FC<Props> = ({ show, handleClose, handleConfirm, title, message, okBtn = 'Tamam', cancelBtn = 'İptal' }) => {

  const handleResult = (result: ConfirmResult) => {
    handleClose();
    handleConfirm(result);
  }

  const onEntered = () => {
    const confirmBackdrops = document.querySelectorAll('div.confirm-backdrop');

    if (confirmBackdrops.length > 0) {
      const lastBackdrop = confirmBackdrops[confirmBackdrops.length- 1] as HTMLElement;
      lastBackdrop.style.zIndex = '1060';
    }
  }

  return (
    <Modal
      id="confirm-dialog"
      tabIndex={-1}
      dialogClassName="modal-dialog-centered mw-1000px"
      show={show}
      onHide={() => handleResult(ConfirmResult.Close)}
      onEntered={onEntered}
      backdropClassName="confirm-backdrop"
    >
      <div className="container px-10 py-10">
        <div className="modal-header py-2 d-flex">
          <h5 className="modal-title">{title}</h5>
          <div
            className="btn btn-icon btn-sm btn-light-primary"
            onClick={() => handleResult(ConfirmResult.Close)}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotone/Navigation/Close.svg"
            />
          </div>
        </div>
        <div className="overlay overlay-block">
          <div className="modal-body">
            {message}
          </div>
          <div className="modal-footer" style={{ justifyContent: 'space-between' }}>
            <a onClick={() => { handleResult(ConfirmResult.Cancel) }} className="btn btn-danger">{cancelBtn}</a>
            <a onClick={() => { handleResult(ConfirmResult.Ok) }} className="btn btn-primary">{okBtn}</a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { Confirm };

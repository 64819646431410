/* eslint-disable react-hooks/exhaustive-deps */
import {
  PageTitle,
} from "../../../_start/layout/core";
import { SalesPage } from "./SalesPage";

export function SalesWrapper() {
  return (
    <>
      <SalesPage/>
      <PageTitle>Açık Hesaplar</PageTitle>
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG } from "../../../helpers";
import { Room } from "../../../../app/types/room";

type Props = {
  className: string;
  innerPadding?: string;
  room: Room;
};

const RoomWidget: React.FC<Props> = ({ className, innerPadding = "", room }) => {
  return (
    <div className={`card ${className}`}>
      <div className={`card-body ${innerPadding}`}>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <span className="symbol-label bg-light-info">
              <KTSVG
                path="/media/icons/duotone/Layout/Layout-4-blocks.svg"
                className="svg-icon-2x svg-icon-info"
              />
            </span>
          </div>
          <div>
            <a className="fs-4 text-gray-800 text-hover-primary fw-bolder">
              {room.name}
            </a>
            <div className="fs-7 text-muted fw-bold mt-1">{room.description}</div>
          </div>
        </div>
        <div className="fw-bolder text-muted pt-7">
          <span className="d-block">{room.totalTables} masa</span>
          <span className="d-block pt-2">{room.activeTables} açık hesap</span>
        </div>
        <div className="progress h-6px  bg-light-info mt-7">
          <div
            className="progress-bar bg-info"
            role="progressbar"
            style={{ width: `${100 * room.activeTables / room.totalTables}%` }}
            aria-valuenow={100 * room.activeTables / room.totalTables}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
    </div>
  );
};

export { RoomWidget };

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import moment from 'moment';
import 'moment/locale/tr';
import { OrderInfo } from "../../../../app/types/order";
import { useHistory } from "react-router-dom";

moment.locale('tr');

type Props = {
  className: string;
  innerPadding?: string;
  sales?: OrderInfo[];
  total?: number;
};

const ClosedOrdersTable: React.FC<Props> = ({ className, innerPadding = "", sales, total = 0 }) => {

  const history = useHistory();

  return (
    <div className={`card ${className}`}>
      <div className={`card-header border-0 pt-5 ${innerPadding}`}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-3">
            Toplam
          </span>
          <span className="text-muted mt-2 fw-bold fs-6">{total} sipariş</span>
        </h3>
      </div>

      <div className="card-body pt-3 pb-0 mt-n3">
        <div className="table-responsive">
          <table className="table table-borderless align-middle">
            <thead>
              <tr>
                <th className="p-0"></th>
                <th className="p-0"></th>
              </tr>
            </thead>
            <tbody>
              {sales && sales.map((sale, index) => 
              <tr key={index} onClick={() => history.push(`/order/${sale.id}`)}>
                <td className="px-0">
                  <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                    {sale.table ? sale.table.name : `Sipariş #${sale.id}` }
                  </a>
                  <span className="text-muted fw-bold d-block mt-1">
                    {`${sale.items.length} adet ürün`}
                  </span>
                </td>
                <td></td>
                <td className="text-end">
                  <span className="text-gray-800 fw-bolder d-block fs-6">
                    {(Object.values(sale.items).flat().reduce((sum, item) => sum + item.price, 0)).toFixed(2)}₺
                  </span>
                  <span className="text-muted fw-bold d-block mt-1 fs-7">
                    {moment(sale.created_at).format('HH:mm, Do MMMM YYYY')}
                  </span>
                </td>
              </tr>)
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export { ClosedOrdersTable };

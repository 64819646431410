/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG } from "../../../helpers";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../../setup";

type Props = {
  className: string;
  innerPadding?: string;
};

const GreetingWidget: React.FC<Props> = ({ className, innerPadding = "" }) => {

  const auth = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  );

  return (
    <div className={`card bg-secondary ${className}`}>
      <div className={`card-body ${innerPadding}`}>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <span className="symbol-label bg-dark bg-opacity-10">
              <KTSVG
                className="svg-icon-2x svg-icon-white"
                path="/media/icons/duotone/Home/Home2.svg"
              />
            </span>
          </div>

          <div>
            <a className="fs-4 text-dark fw-bolder">
              Merhaba {auth ? (auth as any).name : ''}
            </a>
            <div className="fs-7 text-dark opacity-75 fw-bold mt-1">
              {auth && (auth as any).shops[0].name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GreetingWidget };

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG } from "../../../helpers";
import { Table } from "../../../../app/types/table";
import moment from 'moment';
import 'moment/locale/tr';

moment.locale('tr');

type Props = {
  className: string;
  innerPadding?: string;
  table: Table;
};

const TableWidget: React.FC<Props> = ({ className, innerPadding = "", table }) => {

  const getReadableTimeDiff = (start: any, end: any) => {
    const duration = moment.duration(moment(end).diff(moment(start)));
    return duration.humanize();
  }

  return (
    <div className={`card ${className}`}>
      <div className={`card-body ${innerPadding}`}>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <span className={`symbol-label ${table.active_order ? 'bg-primary' : 'bg-gray'}`}>
              <KTSVG
                path="/media/icons/duotone/Home/Library.svg"
                className={`svg-icon-2x ${table.active_order ? 'svg-icon-light' : 'svg-icon-black'}`}
              />
            </span>
          </div>
          <div>
            <a className="fs-4 text-gray-800 text-hover-primary fw-bolder">
              {table.name}
            </a>
            <div className="fs-7 text-muted fw-bold mt-1">{table.description}</div>
          </div>
        </div>
        <div className="fw-bolder text-muted pt-7">
          <span className="d-block">
            {table.active_order ? `Aktif ${table.timing.first !== null ? `: ${getReadableTimeDiff(table.timing.first, Date.now())}` : ''}` : 'Boşta'}
          </span>
          <span className="d-block">
            {table.timing.last !== null ? (
              <>Son sipariş: {moment(table.timing.last).fromNow()}</>
            ) : (
              <>Henüz sipariş yok</>
            )}
          </span>
          <span className="d-block pt-2">{table.amount.payment}₺ / {table.amount.total}₺ ödendi</span>
        </div>
        <div className={`progress h-6px mt-7  ${table.active_order ? 'bg-light-primary' : 'br-light-gray'}`}>
          <div
            className="progress-bar bg-primary"
            role="progressbar"
            style={{ width: `${100 * table.amount.payment / table.amount.total}%` }}
            aria-valuenow={100 * table.amount.payment / table.amount.total}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
    </div>
  );
};

export { TableWidget };

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from "../../../../../_start/helpers";
import InputNumber from "../../../../../_start/partials/widgets/shared/InputNumber";
import { Confirm, ConfirmResult } from "../../../../../_start/partials/Confirm";
import { updateActiveOrderItem } from "../../../../services/api";
import { OrderSelector } from "../../../../types/orderSelector";

type Props = {
  show: boolean;
  handleClose: () => void;
  orderItemGroup: any[];
  order: OrderSelector;
  handleReload: () => void;
};

const ChangeOrderItemModal: React.FC<Props> = ({ show, handleClose, orderItemGroup, handleReload, order }) => {
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState<number>(0);
  const [initialCount, setInitialCount] = useState<number>(0);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [paidItems, setPaidItems] = useState<any[]>([]);

  const loadProducts = () => {
    setLoading(true);
    const paidItems = orderItemGroup.filter(items => items.payment_id !== null);
    const unpaidItems = orderItemGroup.filter(items => items.payment_id === null);
    setItems(unpaidItems);
    setCount(orderItemGroup.length);
    setLoading(false);
    setInitialCount(orderItemGroup.length);
    setPaidItems(paidItems);
  }

  const updateItem = (forcedCount?: number) => {
    const _count = forcedCount ?? count;
    const item = getItem();

    const data = {
      orderId: item.order_id,
      pid: item.pid,
      variantId: item.variant_id,
      price: item.price,
      count: {
        initial: initialCount,
        updated: _count
      }
    };

    if (initialCount === _count) {
      console.log('same count')
      return;
    }

    setLoading(true);

    updateActiveOrderItem(order.orderId.toString(), data).then(() => {
      handleReload();
      setLoading(false);
      handleClose();
    });
  }

  const onRemoveItemsConfirmed = (result: ConfirmResult) => {
    if (result === ConfirmResult.Ok) {
      updateItem(0);
    }
  }

  const onClose = () => {
    setItems([]);
    handleClose();
  };

  const getItem = () => {
    if (items.length > 0) {
      return items[0];
    }

    if (paidItems.length > 0) {
      return paidItems[0];
    }
    return null;
  }

  return (
    <>
      <Modal
        id="change-order-item-modal"
        tabIndex={-1}
        dialogClassName="modal-dialog-centered mw-1000px"
        show={show}
        onHide={onClose}
        onEntered={loadProducts}
      >
        <div className="container px-10 py-10">
          
          <div className="modal-header py-2 d-flex">
            <h5 className="modal-title">Ürün Güncelleme</h5>
            {/* begin::Close */}
            <div
              className="btn btn-icon btn-sm btn-light-primary"
              onClick={onClose}
            >
              <KTSVG
                className="svg-icon-2"
                path="/media/icons/duotone/Navigation/Close.svg"
              />
            </div>
            {/* end::Close */}
          </div>
          <div className="overlay overlay-block">
            <div className="overlay-wrapper">
              <div className="modal-body" style={{height: '40vh', overflowY: 'auto'}}>
                { (items.length > 0 || paidItems.length > 0) &&
                  <div className="card card-custom bg-light mt-5">
                    <div className="card-header" style={{flexDirection: 'column'}}>
                      <h3 className="card-title">{getItem().name}</h3>
                      <p>Şu anda {initialCount} adet var</p>
                      {
                        paidItems.length > 0 && <p>Ancak bu ürünlerin {paidItems.length} tanesi ödendi</p>
                      }
                    </div>
                    <div className="card-body">
                      <label className="form-label mt-3">Adet</label>
                      <div key={count}>
                        <InputNumber steps={1} defaultValue={count} onChange={(value) => { setCount(value) }} minValue={paidItems.length}/>
                      </div>
                      
                      <div key={getItem().price}>
                        <label className="form-label mt-5">Fiyat</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={`${getItem().price} ₺`}
                          readOnly
                          style={{border: 'none', background: 'transparent'}}
                        />
                      </div>
                    </div>
                  </div>
                }

                {
                  count === 0 && <p className="text-danger">Devam ederseniz bu sipariş kaldırılacak</p>
                }
              </div>
              <div className="modal-footer" style={{justifyContent: 'space-between'}}>
                { paidItems.length === 0 &&
                  <a onClick={() => {setShowConfirm(true)}} className="btn btn-danger">Ürünü Kaldır</a>
                }
                <a onClick={() => {updateItem()}} className="btn btn-primary">Güncelle</a>
              </div>
            </div>
            { loading &&
              <div className="overlay-layer rounded bg-white bg-opacity-50">
                <div
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Yükleniyor...</span>
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>
      
      <Confirm show={showConfirm} handleClose={() => setShowConfirm(false)} handleConfirm={onRemoveItemsConfirmed} title="Tümünü Kaldır" message="Onaylamanız durumunda bu ürünün tümü hesaptan kaldırılacaktır."></Confirm>
    </>
  );
};

export { ChangeOrderItemModal };

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { SalesStat } from "../../../../app/types/stat";

type Props = {
  className: string;
  innerPadding?: string;
  titles: string[];
  values: SalesStat;
};

const StatsWidgetSales: React.FC<Props> = ({ className, innerPadding = "", titles, values }) => {

  const [diff, setDiff] = useState(0);

  useEffect(() => {
    setDiff(values.last == 0 ? 0 : 100 * (values.current - values.last) / values.last);
  }, [values]);

  return (
    <div className={`card ${className}`}>
      <div className={`card-body ${innerPadding}`}>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <span className="text-muted fw-bolder pe-2">{titles[0]}</span>
        </div>

        <div className="flex-grow-1">
          <h3 className="fs-3 text-gray-800 text-hover-primary fw-bolder cursor-pointer">
            {values.current.toFixed(2)}₺
          </h3>

          <p className={`${diff === 0 ? 'text-gray-600' : (diff < 0 ? 'text-danger' : 'text-primary')} fs-1 fw-bolder pt-3 mb-0`}>
            %{diff.toFixed(2)}
          </p>
        </div>
      </div>

      <div className="card-footer border-0 d-flex align-items-center justify-content-between pt-0 pb-10">
        {titles[1]}: {values.last.toFixed(2)}₺
      </div>
    </div>
  );
};

export { StatsWidgetSales };

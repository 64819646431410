import { Dictionary } from "./dictionary";
import { Table } from "./table";

export interface OrderModel {
  id: number;
  closing_user_name?: string;
  created_at: string;
  opening_user_name: string;
  status: OrderStatus;
  updated_at?: string;
  items: Dictionary<OrderItemModel[]>;
  amount: any;
}

export interface OrderItemModel {
  id: number;
  order_id: number;
  product_id: number;
  name: string;
  price: number;
  user_id: number;
  user_name: string;
  created_at: string;
  updated_at: string;
  table: Table;
  payment_id?: number;
  pid: string;
  amount: any;
}

export enum OrderStatus {
  Active = 'active',
  Closed = 'closed'
}

export interface OrderInfo extends OrderModel {
  table: Table | undefined;
}
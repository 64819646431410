import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../../_start/partials";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { RoomsWrapper } from "../pages/rooms/RoomsWrapper";
import { TablesWrapper } from "../pages/tables/TablesWrapper";
import { OrderWrapper } from "../pages/orders/OrderWrapper";
import { SalesWrapper } from "../pages/sales/SalesWrapper";
import { ClosedSalesWrapper } from "../pages/sales/closed-sales/ClosedSalesWrapper";

export function PrivateRoutes() {

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardWrapper} />
        <Route path="/rooms" component={RoomsWrapper} />
        <Route path="/tables/:roomId?" component={TablesWrapper} />
        <Route path="/table-order/:tableId" component={OrderWrapper} />
        <Route path="/order/:orderId" component={OrderWrapper} />
        <Route path="/orders" component={SalesWrapper} />
        <Route path="/closed-orders" component={ClosedSalesWrapper} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="dashboard" />
      </Switch>
    </Suspense>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  PageTitle,
} from "../../../_start/layout/core";
import { DashboardPage } from "./DashboardPage";

export function DashboardWrapper() {
  return (
    <>
      <DashboardPage />
      <PageTitle>Özet</PageTitle>
    </>
  );
}

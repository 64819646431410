/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { getClosedOrders } from "../../../services/api";
import { ClosedOrdersTable } from "../../../../_start/partials/widgets/orders/ClosedOrdersTable";
import { OrderInfo } from "../../../types/order";

export const ClosedSalesPage: React.FC = () => {
  const [sales, setSales] = useState<OrderInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = (page = 1) => {
    setIsLoading(true);
    getClosedOrders(page).then((res) => {
      setSales(res.orders);
      setTotal(res.total);

      setPreviousPage(currentPage);
      setCurrentPage(res.pagination.current);
      setMaxPage(res.pagination.max);
      setIsLoading(false);
    });
  }

  const goToPage = (page: number) => {
    loadData(page);
  }

  return (
    <>
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-12">
          <ClosedOrdersTable className="card-stretch" sales={sales} total={total}/>
          { maxPage > 1 &&
            <nav aria-label="Page navigation">
              <ul className="pagination">
                <li className="page-item" style={{pointerEvents: currentPage <= 1 ? 'none' : 'initial'}}>
                  <button className="page-link" onClick={() => {goToPage(1)}} aria-label="First" disabled={currentPage <= 1}>
                    <span aria-hidden="true"><i className="fas fa-angle-double-left"></i></span>
                  </button>
                </li>
                <li className="page-item" style={{pointerEvents: currentPage <= 1 ? 'none' : 'initial'}}>
                  <button className="page-link" onClick={() => {goToPage(currentPage - 1)}} aria-label="Previous" disabled={currentPage <= 1}>
                    <span aria-hidden="true"><i className="fas fa-chevron-left"></i></span>
                  </button>
                </li>
                <li className="page-item" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30vw'}}>
                  <span>{currentPage} / {maxPage}</span>
                  {isLoading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                </li>
                <li className="page-item" style={{pointerEvents: currentPage >= maxPage ? 'none' : 'initial'}}>
                  <button className="page-link" onClick={() => {goToPage(currentPage + 1)}} aria-label="Next" disabled={currentPage >= maxPage}>
                    <span aria-hidden="true"><i className="fas fa-chevron-right"></i></span>
                  </button>
                </li>
                <li className="page-item" style={{pointerEvents: currentPage >= maxPage ? 'none' : 'initial'}}>
                  <button className="page-link" onClick={() => {goToPage(maxPage)}} aria-label="LLast" disabled={currentPage >= maxPage}>
                    <span aria-hidden="true"><i className="fas fa-angle-double-right"></i></span>
                  </button>
                </li>
              </ul>
            </nav>
          }
        </div>
      </div>
    </>
  );
};
